.contact-form {
   /* Style inputs, select elements and textareas */
  input[type=text], input[type=email], select, textarea{
    width: 100%;
    padding: 12px;
    border: 1px solid $global-border-color;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
    [theme=dark] & {
      color: $global-font-secondary-color;
      background: $header-background-color-dark;
      // background: $global-background-color-dark;
      border: 1px solid $global-border-color-dark;
    }
  }

  /* Style the label to display next to the inputs */
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }

  /* Style the submit button */
  button[type=submit] {
    color: $global-font-color;
    background: $global-font-color-dark;
    padding: 8px 16px;
    border: $global-font-color;
    border-radius: 6px;
    cursor: pointer;
    float: right;

    [theme=dark] & {
      color: $global-font-secondary-color;
      // background: $header-background-color-dark;
      background: $global-font-color;
      border: 1px solid $global-border-color-dark;
    }
  }

  /* Style the container */
  .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }

  /* Floating column for labels: 25% width */
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }

  /* Floating column for inputs: 75% width */
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
  }
}
